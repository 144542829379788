
import { Component, Prop, Vue } from "vue-property-decorator";
import { IPropertyInfo } from "@/models/PropertyInfo";
import HousfySvg from "@/components/ui/HousfySvg.vue";
import { PropertyStatus } from "@/enums/PropertyStatus";
import MYHExternalRoutes from "@/services/MYHExternalRoutes";
import { HousfyButton } from "housfy-ui-lib";
import PropertyActiveProcessCard from "@/components/PropertyActiveProcessCard.vue";
import { IRentalLeasings } from "@/models/RentalLeasing";
import OwnerCardsMappingService from "@/services/OwnerCardsMappingService";
import { PropertyActiveProcessCardType } from "@/enums/PropertyCardType";

@Component({
  name: "PropertyInfo",
  components: {
    HousfySvg,
    HousfyButton,
    PropertyActiveProcessCard,
  },
})
export default class PropertyInfo extends Vue {
  @Prop({ type: Object, default: () => {} })
  info!: IPropertyInfo;
  @Prop({ type: Object, default: () => {} })
  leasings!: IRentalLeasings;

  get images(): string[] {
    if (this.mq("sm", "md", "lg")) return this.info?.photos?.slice(0, 5);
    return this.info?.photos?.[0] ? [this.info?.photos?.[0]] : [];
  }

  get emptyImagesLength(): number {
    return this.mq("sm", "md", "lg") ? 5 : 1;
  }

  get imageWidth(): number | string {
    return this.mq("sm", "md", "lg") ? "auto" : 1200;
  }

  get propertyStatus() {
    return PropertyStatus;
  }

  get propertyActiveProcessCardType() {
    return PropertyActiveProcessCardType;
  }

  get activeProcessCardTypes(): PropertyActiveProcessCardType[] {
    const activeProcesses = OwnerCardsMappingService.getActiveProcessCardTypes(
      this.info?.propertyStatus,
      this.leasings?.items,
      this.info?.isVisible
    );

    return activeProcesses;
  }

  get activeProcessesTitle(): boolean {
    return (
      this.activeProcessCardTypes.includes(
        PropertyActiveProcessCardType.RESIGNATION
      ) ||
      this.activeProcessCardTypes.includes(
        PropertyActiveProcessCardType.CAPTURED
      ) ||
      this.activeProcessCardTypes.includes(
        PropertyActiveProcessCardType.PUBLISHED
      ) ||
      this.activeProcessCardTypes.includes(
        PropertyActiveProcessCardType.PENDING_SIGNATURE
      )
    );
  }

  get showProcessCardAsButton() {
    return (
      this.info?.propertyStatus === PropertyStatus.PUBLISHED &&
      this.activeProcessCardTypes.includes(
        PropertyActiveProcessCardType.PUBLISHED
      )
    );
  }

  handleSeePropertyButtonClick() {
    const previewUrl = MYHExternalRoutes.propertyPreview(
      this.info.propertyUuid
    );
    this.$navigate(previewUrl);
  }
}
